<template>
	<div class="floor-style-4" :id="`floor${index}`">
		<div class="head-wrap">
			<h2 style="font-weight: bold;">{{ data.title }}</h2>
			<div class="pagination">
				<span :class="`btn-prev prev${index}`">
					<img src="~img/alibaba/left.png">
				</span>
				<span :class="`btn-next next${index}`">
					<img src="~img/alibaba/right.png">
				</span>
			</div>
		</div>
		<!-- <hr> -->
		<div class="body-wrap">
			<ul class="goods-list">
				<swiper
					:options="swiperOptions"
					>
					<swiper-slide v-for="(item, index) in data.value.goodsList.value.list" :key="index">
						<div class="img-wrap" @click="$router.push({path: '/sku-' + item.goods_id})">
							<img alt="商品图片" :src="$img(firstImg(item.goods_image))"/>
						</div>
						<h3>{{ item.goods_name }}</h3>
						<p class="desc" v-if="!logined">仅会员可见</p>
						<p class="desc" v-if="logined">￥{{ item.price }}</p>
					</swiper-slide>
				</swiper>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex"
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
	name: 'floor-style-4',
	components: {
		Swiper,
		SwiperSlide
	},
	props: {
		data: {
			type: Object
		},
		index: {
			type: Number
		}
	},
	data() {
		const _this = this
		return {
			loadingAd: true,
			swiperOptions: {
				navigation: {
					nextEl: `.next${this.index}`,
					prevEl: `.prev${this.index}`,
				},
				slidesPerView: 5,
				spaceBetween: 10,
				on: {
					slideChange() {
						_this.currentPage = this.activeIndex
					}
				}
			},
			currentPage: 0
		};
	},
	computed: {
		...mapGetters(["member"]),
		logined: function() {
		    return this.member !== undefined && this.member !== "" && this.member !== {}
		}
	},
	methods: {
		goSku(skuId) {
			this.$router.pushToTab('/sku-' + skuId);
		},
		imageError(index) {
			this.data.value.goodsList.value.list[index].goods_image = this.defaultGoodsImage;
		},
		onSwiper(swiper) {
			console.log(swiper);
		},
		firstImg(str) {
			let arr = str.split(',')
			return arr[0] || ''
		}
	}
};
</script>

<style lang="scss">
.floor-style-4 {
	margin-bottom: 40px;
	
	.head-wrap {
		border-bottom: 1px solid rgba(0, 0, 0,0.1);
		padding-bottom: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
		
		h2 {
			font-size: 26px;
			color: $ns-text-color-black;
			font-weight: 400;
			line-height: 40px;
		}
		
		.pagination {
			height: 40px;
			display: flex;
			align-items: center;
			
			span {
				border: 1px solid $ns-text-color-gray;
				margin-left: 14px;
				display: flex;
				width: 24px;
				height: 24px;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				border-radius: 3px;
				transition: all .3s;
				user-select: none;
				
				img {
					width: 12px;
					height: 12px;
				}
				
				&.swiper-button-disabled {
					opacity: .5;
				}
			}
		}
	}
	
	.goods-list {
		.img-wrap {
			width: 100%;
			height: 100%;
			cursor: pointer;
			font-size: 14px;
			
			img {
				width: 292px;
				height: 292px;
				object-fit: cover;
			}
		}
	}
	
	.swiper-slide {
		background-color: $base-color-gray;
		padding-bottom: 10px;
		transition: all .3s;
		
		h3 {
			font-size: $ns-font-size-lg;
			color: $ns-text-color-black;
			font-weight: 400;
			text-align: center;
			margin-top: 10px;
			line-height: 25px;
			transition: all .3s;
			font-size: 16px;
		}
		
		.desc {
			font-size: $ns-font-size-base;
			color: $ns-text-color-gray;
			text-align: center;
			line-height: 25px;
			transition: all .3s;
			font-size: 12px;
		}
		
		&:hover {
			background-color: $base-color;
			
			h3, .desc {
				color: #ffffff;
			}
		}
	}
}
</style>
