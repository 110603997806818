<template>
	<div class="banner-cpt">
		<el-image
			:src="defaultImg"></el-image>
		<div class="banner-text">
			<div class="title-en">SPRINGTIME</div>
			<h2>春风送暖全店惠动</h2>
			<router-link to="/">查看详情</router-link>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'banner',
	props: {
		data: {
			type: Object
		}
	},
	data() {
		return {
			defaultImg: '~img/test.jpg'
		};
	},
	created() {},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	methods: {
		imageError(index) {
			this.data.value.goodsList.value.list[index].goods_image = this.defaultGoodsImage;
		}
	}
};
</script>

<style lang="scss">
.banner-cpt {
	width: 100%;
	height: 300px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	
	.el-image {
		height: 100%;
		width: 100%;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		
		img {
			height: 100%;
		}
	}
	
	.banner-text {
		position: relative;
		z-index: 10;
		
		.title-en {
			font-size: $ns-font-size-lg;
			color: $ns-text-color-black;
			letter-spacing: 2px;
			text-align: center;
		}
		
		h2 {
			font-size: 32px;
			color: $ns-text-color-black;
			
			&:after {
				content: '';
				background-color: $ns-text-color-black;
				height: 1px;
				width: 80px;
				margin: 0px auto;
				display: block;
			}
		}
		
		a {
			color: #fff;
			border-radius: 5px;
			background-color: $base-color;
			border: 1px solid $base-color;
			width: 120px;
			height: 38px;
			font-size: $ns-font-size-lg;
			text-align: center;
			line-height: 38px;
			margin: 30px auto 0;
			display: block;
			transition: all .3s;
			
			&:hover {
				background-color: #fff;
				color: $base-color;
			}
		}
	}
}
</style>
