<template>
    <div>
        <!-- 首页固定区 -->
        <div class="index-wrap" :style="{ background: backgroundColor }">
            <div class="index">
                <div class="banner">
                    <el-carousel height="500px" width="100%" arrow="hover" v-loading="loadingAd" @change="handleChange">
                        <el-carousel-item v-for="item in adList" :key="item.adv_id">
                            <el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- 领券中心 -->
            <!-- <div class="content-div" v-if="addonIsExit.coupon == 1 && couponList.length > 0">
				<div class="coupon">
					<div class="coupon-title">
						<p class="coupon-font">领券中心</p>
						<p class="coupon-en">coupon</p>
						<p class="coupon-more" @click="$router.push('/coupon')">
							<span>更多</span>
							<i class="iconfont iconarrow-right"></i>
						</p>
					</div>
					<ul class="coupon-list">
						<li v-for="(item, index) in couponList" :key="index">
							<p class="coupon-price ns-text-color" v-if="item.type == 'reward'" :class="{ disabled: item.useState == 2 }">
								￥
								<span>{{parseInt(item.money) }}</span>
							</p>
							<p class="coupon-price ns-text-color" v-else-if="item.type == 'discount'" :class="{ disabled: item.useState == 2 }">
								
								<span>{{ parseFloat(item.discount) }}</span>折
							</p>
							<p class="coupon-term" v-if="item.at_least > 0" :class="{ disabled: item.useState == 2 }">满{{ item.at_least }}可用</p>
							<p class="coupon-term" v-else :class="{ disabled: item.useState == 2 }">满{{ item.at_least }}可用</p>
							<p class="coupon-receive ns-text-color" @click="couponTap(item, index)">
								<span v-if="item.useState == 0">立即领取</span>
								<span v-else>去使用</span>
								<i class="iconfont iconarrow-right"></i>
							</p>
						</li>
					</ul>
				</div>
			</div>
 -->
            <!-- 广告 -->
            <!-- <div class="content-div" v-if="adLeftList.length > 0 || adRightList.length > 0">
				<div class="ad-wrap">
					<div class="ad-big">
						<div class="ad-big-img" v-for="(item, index) in adLeftList" :key="index">
							<el-image :src="$img(item.adv_image)" fit="cover" @error="adLeftImageError(index)" @click="$router.pushToTab(item.adv_url.url)"></el-image>
						</div>
					</div>
					<div class="ad-small">
						<div class="ad-small-img" v-for="(item, index) in adRightList" :key="index">
							<el-image :src="$img(item.adv_image)" fit="cover" @error="adRightImageError(index)" @click="$router.pushToTab(item.adv_url.url)"></el-image>
						</div>
					</div>
				</div>
			</div>
 -->
            <!-- 限时秒杀 -->
            <!-- <div class="content-div" v-if="addonIsExit.seckill == 1 && listData.length > 0">
				<div class="seckill-wrap">
					<div class="seckill-time">
						<div class="seckill-time-left">
							<i class="iconfont iconmiaosha1 ns-text-color"></i>
							<span class="seckill-time-title ns-text-color">限时秒杀</span>
							<span>{{ seckillText }}</span>
							<count-down
								class="count-down"
								v-on:start_callback="countDownS_cb()"
								v-on:end_callback="countDownE_cb()"
								:currentTime="seckillTimeMachine.currentTime"
								:startTime="seckillTimeMachine.startTime"
								:endTime="seckillTimeMachine.endTime"
								:dayTxt="'：'"
								:hourTxt="'：'"
								:minutesTxt="'：'"
								:secondsTxt="''"
							></count-down>
						</div>
						<div class="seckill-time-right" @click="$router.push('/promotion/seckill')">
							<span>更多商品</span>
							<i class="iconfont iconarrow-right"></i>
						</div>
					</div>
					<div class="seckill-content">
						<vue-seamless-scroll :data="listData" :class-option="optionLeft" class="seamless-warp2">
							<ul class="item" :style="{ width: 250 * listData.length + 'px' }">
								<li v-for="(item, index) in listData" :key="index">
									<div class="seckill-goods" @click="$router.pushToTab('/promotion/seckill-' + item.id)">
										<div class="seckill-goods-img"><img :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" /></div>
										<p>{{ item.sku_name }}</p>
										<div class="seckill-price-wrap">
											<p class="ns-text-color">
												￥
												<span>{{ item.seckill_price }}</span>
											</p>
											<p class="primary-price">￥{{ item.price }}</p>
										</div>
									</div>
								</li>
							</ul>
						</vue-seamless-scroll>
					</div>
				</div>
			</div>
 -->
            <!-- 楼层区 -->
            <div class="content-div">
                <div class="floor">
                    <div v-for="(item, index) in floorList" :key="index" class="floor_item">
                        <floor-style-4 :data="item" :index="index" />
                    </div>
                </div>
                <div class="floor-category">
                    <div class="floor">
                        <div class="floor-title">
                            <h2>进口家居生活百货日用供应链引导者</h2>
                            <div class="floor-title-en">CATEGORY INDEX</div>
                        </div>
                        <div class="category-list">
                            <div class="category-item" v-for="item in catGoods" @click="$router.push('/category?category_id=' + item.category_id)">
                                <div class="category-icon">
                                    <img :src="$img(item.image_adv)" />
                                </div>
                                <div class="category-title">{{ item.category_name }}</div>
                                <!-- <div class="category-title-en">ABC</div> -->
                            </div>
                        </div>
                    </div>
                </div>
         
				<div class="floor">
                    <!-- <floor-style-4 /> -->
                    <div v-for="(item, index) in catGoods" :key="index" :id="`floor-${index}`">
                        <floor-style-5 :data="item" />
                    </div>
                    <!-- <div v-for="(item, index) in floorList" :key="index" class="floor_item">
						<floor-style-1 v-if="item.block_name == 'floor-style-1'" :data="item" />
						<floor-style-2 v-if="item.block_name == 'floor-style-2'" :data="item" />
						<floor-style-3 v-if="item.block_name == 'floor-style-3'" :data="item" />
					</div> -->
                </div>
                <!-- <banner/> -->
            </div>

            <!-- 悬浮搜索 -->
            <div class="fixed-box" :style="{ display: isShow ? 'block' : 'none' }">
                <div class="header-search">
                    <el-row>
                        <el-col :span="9">
                            <router-link to="/" class="logo-wrap">
                                <img src="@/assets/images/logo.png" />
                            </router-link>
                        </el-col>
                        <el-col :span="15" class="cart-box">
                            <div class="in-sousuo">
                                <div class="sousuo-box">
                                    <div class="sousuo-inner-box">
                                        <input type="text" :placeholder="defaultSearchWords" v-model="keyword" @keyup.enter="search" maxlength="50" />
                                        <div class="sousuo-tips">关键词，产品名称，型号，条形码均可搜索</div>
                                    </div>
                                    <el-button type="primary" size="small" @click="search">搜索</el-button>
                                </div>
                            </div>
                            <div class="cart-wrap clearfix">
                                <router-link class="cart" to="/cart">
                                    <i class="iconfont icongouwuche"></i>
                                    <el-badge v-if="cartCount" :value="cartCount" type="primary" class="mycart-number"></el-badge>
                                    <el-badge v-else type="primary"> </el-badge>
                                    <span>我的购物车</span>
                                </router-link>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import floorStyle4 from "./components/floor-style-4.vue"
import floorStyle5 from "./components/floor-style-5.vue"
import Banner from "./components/banner.vue"
import index from "./_index/index.js"

export default {
    name: "index",
    components: { floorStyle4, floorStyle5, Banner },
    mixins: [index]
}
</script>

<style lang="scss" scoped>
@import "./_index/index.scss";
</style>

<style lang="scss">
body {
    background-color: #ffffff;
}
.count-down {
    span {
        display: inline-block;
        width: 22px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        background: #383838;
        color: #ffffff;
        border-radius: 2px;
    }
}
.el-footer {
    padding-top: 0 !important;
    background-color: #f7f7f7 !important;
}
h2 {
    font-size: 30px;
}
.mycart-number {
    position: absolute;
    right: 199px;
    top: 12px;
}
</style>
