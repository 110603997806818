<template>
    <div class="floor-style-5">
        <div class="head-wrap">
            <h2>
                {{ data.category_name }}
            </h2>
        </div>
        <div class="body-wrap">
            <router-link :to="`/category?category_id=${data.category_id}`"
                ><div class="store-main goods-item" :style="`background-image: url(${$img(data.image_adv)});`">
                    <router-link :to="`/category?category_id=${data.category_id}`" target="_blank"> 查看更多商品<span></span></router-link></div
            ></router-link>
            <div class="goods-item" v-for="(item, index) in data.goodsList" :key="index">
                <div class="goods-img" @click="$router.push({ path: '/sku-' + item.goods_id })">
                    <el-image :src="$img(firstImg(item.goods_image))" fit="cover"></el-image>
                </div>
                <div class="goods-text">
                    <h2>{{ item.goods_name }}</h2>
                    <div class="goods-tips" v-if="logined">￥{{ item.price }}</div>
                    <div class="goods-tips" v-if="!logined">会员可见</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    name: "floor-style-5",
    props: {
        data: {
            type: Object
        }
    },
    data() {
        return {
            defaultImg: "~img/test.jpg"
        }
    },
    created() {},
    computed: {
        ...mapGetters(["member"]),
        logined: function () {
            return this.member !== undefined && this.member !== "" && this.member !== {}
        }
    },
    methods: {
        goSku(skuId) {
            this.$router.pushToTab("/sku-" + skuId)
        },
        imageError(index) {
            this.data.value.goodsList.value.list[index].goods_image = this.defaultGoodsImage
        },
        firstImg(str) {
            let arr = str.split(",")
            return arr[0] || ""
        }
    }
}
</script>

<style lang="scss">
.floor-style-5 {
    margin-top: 20px;

    .head-wrap {
        padding-bottom: 10px;

        h2 {
            color: $ns-text-color-black;
            font-size: 26px;
            font-weight: 400;

            span {
                color: $ns-text-color-gray;
                font-size: 20px;
                margin-left: 10px;
                vertical-align: -2px;
            }
        }
    }

    .body-wrap {
        margin-top: 10px;

        &:after {
            content: "";
            display: block;
            width: 0;
            clear: both;
        }

        .goods-item {
            width: 360px;
            float: left;
            margin: 0 20px 20px 0;

            &:nth-child(7) {
                margin-right: 0;
            }

            &:nth-child(3) {
                margin-right: 0;
            }

            &.store-main {
                width: 740px;
                height: 460px;
                padding: 40px;
                box-sizing: border-box;
                background-image: url(~img/test.jpg);
                background-size: cover;

                .store-title {
                    font-size: 30px;
                    color: $ns-text-color-black;
                    line-height: 45px;
                }

                .store-tags {
                    display: flex;
                    align-items: center;

                    span {
                        display: block;
                        position: relative;
                        font-size: 20px;
                        margin-top: 10px;
                        color: $ns-text-color-gray;
                        padding: 0 10px;

                        &:after {
                            content: "/";
                            display: block;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            font-size: 20px;
                            color: $ns-text-color-gray;
                        }

                        &:last-child:after {
                            display: none;
                        }

                        &:first-child {
                            padding-left: 0;
                        }
                    }
                }

                a {
                    width: 150px;
                    height: 40px;
                    margin-top: 30px;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    background-color: $base-color;
                    color: #fff;
                    border-radius: 20px;
                    font-size: $ns-font-size-base;
                    box-sizing: border-box;
                    border: 1px solid $base-color;
                    transition: all 0.3s;

                    span {
                        display: block;
                        width: 12px;
                        height: 12px;
                        margin-left: 10px;
                        background-image: url(~img/alibaba/right-fff.png);
                        background-size: 100%;
                    }
                }
            }

            .goods-img {
                width: 360px;
                height: 360px;
                overflow: hidden;

                .el-image {
                    width: 100%;
                    height: 100%;
                }
            }

            .goods-text {
                // background-color: $base-color-gray;
                height: 100px;
                width: 360px;
                display: flex;
                justify-content: center;
                flex-flow: column;

                h2 {
                    color: $ns-text-color-black;
                    font-size: $ns-font-size-lg;
                    text-align: center;
                    font-weight: 400;
                    font-size: 18px;
                }

                .goods-tips {
                    color: $ns-text-color-gray;
                    font-size: $ns-font-size-base;
                    text-align: center;
                    font-size: 12px;
                }
            }
            &:hover {
                background-color: $base-color;

                h2,
                .goods-tips {
                    color: #ffffff;
                }
            }
        }
    }
}
</style>
